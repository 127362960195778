


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TOpenEwSharerPayload } from '@/_store/ew-sharer.store';
import { TGetEventListParams } from '@/_types/get-event-list-params.type';
import IconFeatherShare from '@/_modules/icons/components/icon-feather-share.vue';
import iconFeatherStar from '@/_modules/icons/components/icon-feather-star.vue';
import iconSchedule from '@/_modules/icons/components/icon-schedule.vue';
import eventsFilter from '../../../../views/components/eventsFilter/eventsFilter.vue';

@Component({
  components: {
    IconFeatherShare,
    iconFeatherStar,
    iconSchedule,
    eventsFilter,
  },
})
export default class EventList extends Vue {

  @Getter('ewSharerStore/isSharerVisible') isSharerVisible: boolean;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => Promise<void>;
  @Action('ewSharerStore/closeSharer') closeSharer: () => Promise<void>;
  @Action('eventListStore/getPastEvents') callEventListMyPast: (payload: TGetEventListParams) => Promise<void>;
  @Action('eventListStore/getMyEvents') getMyEvents: (payload: TGetEventListParams) => Promise<void>;
  @Action('eventListStore/getPublicEvents') getPublicEvents: (payload: TGetEventListParams) => Promise<void>;
  @Action('_eventStore/removeEventFromFavorites') removeEventFromFavorites: (payload: { eventId: number }) => void;
  @Action('_eventStore/addEventToFavorites') addEventToFavorites: (payload: { eventId: number }) => void;
  @Getter('eventListStore/eventList') eventList: TEvent[];
  @Getter('eventListStore/eventListPast') eventListPast: TEvent[];
  @Getter('eventListStore/isEventListLoading') isEventListLoading: boolean;

  public pastEventsSliderPosition: { marginLeft: number; maxMargin: number} = {
    marginLeft: 0,
    maxMargin: 1088
  }

  public sharerPreviousId: number = null;
  public basicEventListParams: TGetEventListParams = {
    offset: 0,
    limit: 500
  };
  public listFilteringParams: TGetEventListParams = {
    country_id: null,
    city_id: null,
    start_from: null,
    start_to: null,
    end_from: null,
    end_to: null,
    categories: null,
    event_types: [],
  };

  public get listType(): 'all' | 'own' {
    if (this.$route.name === 'event-list') {
      return 'all';
    }
    return 'own';
  }

  public get isFilterVisible(): boolean {
    return this.listType === 'all';
  }

  public get eventListPastReversed(): TEvent[] {
    if (this.eventListPast && this.eventListPast.length) {
      return this.eventListPast.slice().reverse();
    }
    return [];
  }

  public get isPastEventsListEmpty(): boolean {
    return this.eventListPastReversed.length === 0;
  }

  public get isPastEventsBlockVisible(): boolean {
    return this.isListTypeOwnEvents && !this.isPastEventsListEmpty;
  }

  public get isListTypeOwnEvents(): boolean {
    return this.$route.name === 'event-list-my';
  }

  public get reversedEventList(): TEvent[] {
    if (this.eventList && this.eventList.length) {
      return this.isListTypeOwnEvents ? this.eventList : this.eventList.slice().reverse();
    }
    return [];
  }

  @Watch('isListTypeOwnEvents')
  private onIsListTypeOwnEventsChange(newVal: boolean): void {
    if (newVal) {
      this.requestPastEvents();
    }
  }

  @Watch('eventListPast', { deep: true })
  private onEventListPastChange(): void {
    this.setPastEventsWidth();
    this.$nextTick(this.updatePastSliderMaxMargin);
  }

  public created(): void {
    if (this.isListTypeOwnEvents) {
      this.requestPastEvents();
    }

    this.requestUpcomingEvents();
  }

  public mounted(): void {
    window.addEventListener('resize', this.updatePastSliderMaxMargin);
  }

  public onBeforeDestroy(): void {
    window.removeEventListener('resize', this.updatePastSliderMaxMargin);
  }

  public movePastEventsSlider(direction: 'left' | 'right'): void {
    const viewport = this.$refs.pastEventsBlock as HTMLDivElement;
    const list = this.$refs.pastEventsList as HTMLElement;
    const viewportWidth = viewport.getBoundingClientRect().width;
    const singleEvent = (this.$refs.pastEventsList as HTMLElement).getElementsByClassName('past-ev')[0];
    const singleEventMargin = parseInt(window.getComputedStyle(singleEvent).marginRight, 10);
    const singleEventWidth = singleEvent.getBoundingClientRect().width + singleEventMargin;
    const shiftBy: number = Math.floor(viewportWidth / singleEventWidth) * singleEventWidth;
    let newMargin: number = list.style.marginLeft ? parseInt(list.style.marginLeft, 10) : 0;
    switch (direction) {
      case 'left':
        newMargin = newMargin - shiftBy;
        break;
      case 'right':
      default:
        newMargin = newMargin + shiftBy;
    }
    this.pastEventsSliderPosition.maxMargin = list.getBoundingClientRect().width - viewportWidth;
    if (direction) {
      this.pastEventsSliderPosition.marginLeft = newMargin;
      list.style.marginLeft = newMargin !== 0 ? newMargin + 'px' : '0';
    }
  }

  public updatePastSliderMaxMargin(): void {
    const viewport = this.$refs.pastEventsBlock as HTMLElement;
    const list = this.$refs.pastEventsList as HTMLElement;

    if (!viewport || !list) {
      return;
    }

    const viewportWidth = viewport.getBoundingClientRect().width;
    this.pastEventsSliderPosition.maxMargin = list.getBoundingClientRect().width - viewportWidth;
  }

  public setPastEventsWidth(): string {
    const itemWidth = 182;
    const marginRight = 11;
    if (this.eventListPast) {
      return (((itemWidth + marginRight) * this.eventListPast.length - marginRight) + 'px');
    }
    return '100%';
  }

  public requestPastEvents(): void {
    this.callEventListMyPast({
      end_to: this.$moment().utc().subtract(1, 'minute').format('YYYY-MM-DDTHH:mm'),
      limit: 50
    });
  }

  public onEventsFiltered(): void {
    this.requestUpcomingEvents();
  }

  public requestUpcomingEvents(): void {
    if (this.isListTypeOwnEvents) {
      this.getMyEvents(this.getPreparedEventListParams());
      return;
    }
    this.getPublicEvents(this.getPreparedEventListParams());
  }

  public getPreparedEventListParams(): TGetEventListParams {
    const formData = this.listFilteringParams;
    const newObj: any = {};
    Object.keys(formData).forEach(prop => {
      if (prop === 'end_from') {
        newObj[prop] = formData[prop as keyof TGetEventListParams] || this.getDefaultEndFromListParam();
      } else if (formData[prop as keyof TGetEventListParams] !== null && formData[prop as keyof TGetEventListParams] !== '') {
        newObj[prop] = formData[prop as keyof TGetEventListParams];
      }
    });
    return Object.assign(newObj, this.basicEventListParams) as TGetEventListParams;
  };

  public getDefaultEndFromListParam(): string {
    return this.isListTypeOwnEvents ?
      this.$moment().format('YYYY-MM-DDTHH:mm') :
      this.$moment().subtract(5, 'years').format('YYYY-MM-DDTHH:mm');
  }

  public toggleSharer(id: number, event: PointerEvent): boolean {
    const urlToShare = window.location.protocol + '//' + window.location.host + this.$router.resolve({
      name: 'event-info',
      params: { eventId: '' + id},
    }).href;

    if (this.sharerPreviousId === id) {
      this.closeSharer();
      this.sharerPreviousId = null;
      return false;
    }

    this.openSharer({ eventTarget: event.target as HTMLElement, url: urlToShare });
    this.sharerPreviousId = id;

    return false;
  }

  public toggleFavorite(ev: TEvent): void {
    if (ev.personal.is_favorite) {
      this.removeEventFromFavorites({ eventId: ev.id });
    } else {
      this.addEventToFavorites({ eventId: ev.id });
    }
    ev.personal.is_favorite = !ev.personal.is_favorite;
  }

  public onEventListItemClick(id: number, event: TEvent, subRoute: string = ''): void {
    // TODO: different routes when we decide to put booth and tickets buttons back again
    switch (subRoute) {
      case 'booth':
      case 'tickets':
      default:
        this.$router.push({name: 'event-info', params: { eventId: '' + id}});
    }
  }

  public setFiltersData(data: TGetEventListParams): void {
    this.basicEventListParams.offset = 0;
    Object.assign(this.listFilteringParams, data);
  }

  public getDateFormattedAsHTML(ev: TEvent): string {
    let html: string;
    const $moment = this.$moment;

    const year_start = $moment(ev.date_start).format('YYYY');
    const year_end = $moment(ev.date_end).format('YYYY');

    const month_start = $moment(ev.date_start).format('MM');
    const month_end = $moment(ev.date_end).format('MM');

    const day_start = $moment(ev.date_start).format('DD');
    const day_end = $moment(ev.date_end).format('DD');

    const year_current = $moment().format('YYYY');
    let y_start_html = '';
    if (year_current !== year_start) {
      y_start_html = '<span class="year">' + year_start + '</span>';
    }

    if (year_start !== year_end) {
      // Fully different dates
      const m_start = $moment(ev.date_start).format('MMMM');
      const d_start = $moment(ev.date_start).format('DD');
      html = '<span class="period period-different"><span class="period-start"><span class="month">' + m_start + '</span><span class="day">' + d_start + '</span>' + y_start_html + '</span></span>';
    } else if (month_start !== month_end) {
      // Same year, different months
      const m_start = $moment(ev.date_start).format('MMMM');
      const d_start = $moment(ev.date_start).format('DD');
      html = '<span class="period period-same-year"><span class="period-start"><span class="month">' + m_start + '</span><span class="day">' + d_start + '</span>' + y_start_html + '</span></span>';
    } else if (day_start !== day_end) {
      // Same month, different days
      const m = $moment(ev.date_start).format('MMMM');
      const d_start = $moment(ev.date_start).format('DD');
      const d_end = $moment(ev.date_end).format('DD');
      html = '<span class="period period-same-month"><span class="month">' + m + '</span><span class="day">' + d_start + '-' + d_end + '</span>' + y_start_html + '</span>';
    } else {
      // One-day event
      const m = $moment(ev.date_start).format('MMMM');
      const d = $moment(ev.date_start).format('DD');
      html = '<span class="period period-same-month period-same-day"><span class="month">' + m + '</span><span class="day">' + d + '</span>' + y_start_html + '</span>';
    }

    return html;

  }

  public onNoEventsCreateEventClick(): void {
    this.$router.push({ name: 'event-create' });
  }

  public prepareForHTML(inputString: string): string {
    return (inputString || '')
      .replace(/(<br\s*\/*>)|(<\/*p\s*>)|(<\/*ol\s*>)|(<\/*ul\s*>)|(<\/*li\s*>)/ig, '')
      .replace(/&nbsp;|&amp;nbsp;|&amp;nbsp/g, ' ')
      .replace(/</g, '&lt;');
  }

}

